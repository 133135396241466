export const apiUrls = {
  channel: '/api/v1/channels',
  deleteChannel: (id: number) => `/api/v1/channels/${id}`,
  eventType: '/api/v1/eventTypes',
  eventTypeById: (id: number) => `/api/v1/eventTypes/${id}`,
  template: '/api/v1/templates',
  templateById: (id: number) => `/api/v1/templates/${id}`,
  sendTemplatePreview: 'api/v1/templates/preview',
  event: '/api/v1/events',
  eventById: (id: number) => `/api/v1/events/${id}`,
  csdUser: 'user/v1/csd/users',
  userSubscription: '/api/v1/subscriptions',
  toggleSubscriptionActivation: '/api/v1/subscriptions',
  userContacts: (id: number) => `/api/v1/contacts/user/${id}`,
  contacts: '/api/v1/contacts',
  getContactById: (id: number) => `/api/v1/contacts/${id}`,
  dndFilters: '/api/v1/dndFilters',
  dndFiltersById: (id: number) => `/api/v1/dndFilters/${id}`,
  providers: '/api/v1/providers',
  executionSummary: 'api/v1/executionsummaries',
  executionSummaryByEventId: (eventId: number) => `api/v1/executionsummaries/${eventId}`,
  rootTemplate: '/api/v1/templates/root',
  category: '/api/v1/categories',
  categoryById: (id: number) => `/api/v1/categories/${id}`,
  user: '/api/v1/users',
  systemMessage: 'api/v1/systemmessages',
  systemMessageById: (id: number) => `/api/v1/systemmessages/${id}`,
};
