import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { IAPICustomerRef } from '../Interfaces';

@modelProtection
export class CustomerRefModel extends CoreModel  implements ISelectOption{
  number: string;
  partyId: number;

  constructor(data?: Partial<CustomerRefModel>) {
    super();
    Object.assign(this, data);
  }

  static deserialize(apiData: IAPICustomerRef): CustomerRefModel {
    if (!apiData) {
      return new CustomerRefModel();
    }

    const data: Partial<CustomerRefModel> = {
      id: apiData.customerId || apiData.id,
      name: apiData.name,
      number: apiData.number,
      partyId: apiData.partyId,
    };

    return new CustomerRefModel(data);
  }

  static deserializeList(apiDataList: IAPICustomerRef[]): CustomerRefModel[] {
    return apiDataList ? apiDataList.map((apiData: IAPICustomerRef) => CustomerRefModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
