import { CoreModel, EntityMapModel, modelProtection } from '@wings-shared/core';
import { IAPIGeneral, IAPIGeneralRequest } from '../Interfaces';
 
@modelProtection
export class GeneralModel extends CoreModel {
  charterMaxLiquidInML: number = null;
  isMedicalInsuranceRecommended: boolean = false;
  navigators: EntityMapModel[] = [];
  fullAviationSecurityCheckRqrdOnDepartures: EntityMapModel[] = [];
  countryId?: number = null;
 
  constructor(data?: Partial<GeneralModel>) {
    super(data);
    Object.assign(this, data);
  }
 
  static deserialize(apiData: IAPIGeneral): GeneralModel {
    if (!apiData) {
      return new GeneralModel();
    }
    const data: Partial<GeneralModel> = {
      ...apiData,
      navigators: apiData.navigators?.map(
        entity =>
          new EntityMapModel({
            id: entity.appliedNavigatorId,
            entityId: entity.navigator.navigatorId,
            name: entity.navigator.name,
          })
      ),
      fullAviationSecurityCheckRqrdOnDepartures: apiData.fullAviationSecurityCheckRqrdOnDepartures?.map(
        entity =>
          new EntityMapModel({
            id: entity.fullAviationSecurityCheckRqrdOnDepartureId,
            entityId: entity.flightOperationalCategoryId,
            name: entity.name,
          })
      ),
      ...CoreModel.deserializeAuditFields(apiData),
    };
    return new GeneralModel(data);
  }
 
  static deserializeList(apiDataList: IAPIGeneral[]): GeneralModel[] {
    return apiDataList ? apiDataList.map(apiData => GeneralModel.deserialize(apiData)) : [];
  }
 
  //serialize object for create/update API
  public serialize(): IAPIGeneralRequest {
    return {
      id: this.id || 0,
      countryId: this.countryId,
      charterMaxLiquidInML: Number(this.charterMaxLiquidInML),
      isMedicalInsuranceRecommended: this.isMedicalInsuranceRecommended,
      navigators: this.navigators?.map(entity => {
        return {
          id: entity.id || 0,
          navigatorId: entity.entityId,
        };
      }),
      fullAviationSecurityCheckRqrdOnDepartures: this.fullAviationSecurityCheckRqrdOnDepartures.map(entity => {
        return {
          id: entity.id || 0,
          flightOperationalCategoryId: entity.entityId,
          name: entity.name,
        };
      }),
    };
  }
}