import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    inputControlSection: {
      padding: '20px',
    },
    inputControlContent: {
      padding: '20px',
      resize: 'none',
    },
  });