import { Observable } from 'rxjs';
import { BaseStore } from './Base.store';
import { HttpClient } from '../Tools';
import { observable } from 'mobx';
import { aircraftURLs } from './ApiUrls';
import { baseApiPath } from '../API';
import {
  EntityMapModel,
  IAPIGridRequest,
  Utilities,
} from '@wings-shared/core';

import { map } from 'rxjs/operators';
import { Logger } from '@wings-shared/security';
export class BaseAircraftStore extends BaseStore {
  @observable public bannedAircraft: EntityMapModel[] = [];
  @observable public noiseChapters: EntityMapModel[] = [];

  /* istanbul ignore next */
  public getBannedAircraft(pageRequest?: IAPIGridRequest): Observable<EntityMapModel[]> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.aircraft,
    });
    const params: string = Utilities.buildParamString({
      pageSize: 0,
    });
    return http.get(`/${aircraftURLs.ICAOTypeDesignator}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => {
        this.bannedAircraft = response.results?.map(
          x =>
            new EntityMapModel({
              ...x,
              id: 0,
              entityId: x.id,
              name: x.name,
              code: x.code,
            })
        );
        return this.bannedAircraft;
      })
    );
  }

  /* istanbul ignore next */
  public getNoiseRestrictedAircraft(pageRequest?: IAPIGridRequest): Observable<EntityMapModel[]> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.aircraft,
    });
    const params: string = Utilities.buildParamString({
      pageSize: 0,
    });
    return http.get(`/${aircraftURLs.noiseChapter}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => {
        this.noiseChapters = response.results?.map(
          x =>
            new EntityMapModel({
              ...x,
              id: 0,
              entityId: x.id,
              name: x.name,
              code: x.code,
            })
        );
        return this.noiseChapters;
      })
    );
  }
}
